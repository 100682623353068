@import 'mantine';
:root {
  --space-b: rem(16);
  --space-xb: rem(24);
}

html,
body,
#__next {
  min-height: 100%;
  flex: 1 1 0%;
  flex-direction: column;
  display: flex;
}

a,
.link {
  // .link is a utility class to simulate the style of an <a> tag
  cursor: pointer;
  color: var(--mantine-color-blue-0);
  text-decoration: none;

  &[aria-disabled='true'] {
    color: var(--mantine-color-gray-6);
    text-decoration: none;
    cursor: default;

    &:hover,
    &:active {
      color: var(--mantine-color-gray-6);
      text-decoration: none;
    }
  }

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: var(--mantine-color-gray-9);
    text-decoration: none;
  }
}

a.plain {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: var(--mantine-color-gray-7);
  }
}

a.plainOnDark {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: var(--mantine-color-gray-6);
  }
}

// Used in mobile navigation
// TODO rename?
.box {
  background-color: var(--mantine-color-gray-4);
  border-radius: var(--mantine-radius-sm);
  padding: rem(8);
}

body {
  @include body-default-mobile;

  // Check: https://fonts.studiofeixen.ch/Wiki/
  font-feature-settings: 'ss01', 'ss02', 'ss06';

  @include larger-than($mantine-breakpoint-desktop) {
    @include body-default-desktop;
  }
}

.inputRow {
  display: flex;
  flex-direction: column;
  gap: rem(16);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    flex-direction: row;
    gap: rem(32);

    & > * {
      flex: 1;
    }
  }
}

h1,
h2 {
  text-wrap: balance;

  // Check: https://fonts.studiofeixen.ch/Wiki/
  font-feature-settings: 'ss01', 'ss02', 'ss06', 'ss07';
}

.small {
  @include body-small;
}

.extra-small {
  @include body-extra-small-mobile;

  @include larger-than($mantine-breakpoint-desktop) {
    @include body-extra-small-desktop;
  }
}

h1 {
  @include h2-mobile; // Yes, this is correct

  @include larger-than($mantine-breakpoint-tablet) {
    @include h1-mobile;
  }

  @include larger-than($mantine-breakpoint-desktop) {
    @include h1-desktop;
  }
}

h2 {
  @include h2-mobile;

  @include larger-than($mantine-breakpoint-desktop) {
    @include h2-desktop;
  }
}

h3 {
  @include h3-mobile;

  @include larger-than($mantine-breakpoint-desktop) {
    @include h3-desktop;
  }
}

h4 {
  @include h4-mobile;

  @include larger-than($mantine-breakpoint-desktop) {
    @include h4-desktop;
  }
}

h5 {
  @include h5-mobile;

  @include larger-than($mantine-breakpoint-desktop) {
    @include h5-desktop;
  }
}

h6 {
  @include h6;
}

@supports (-moz-appearance: none) {
  input[readonly][aria-haspopup] {
    pointer-events: auto !important;
  }
}

// Intercom ends up shifting page content
.intercom-app {
  height: 0;
  width: 0;
}

// Recaptcha should be hidden
.grecaptcha-badge {
  visibility: hidden;
}
