@import 'mantine';
.input {
  background-color: var(--mantine-color-gray-4);
  padding-block: rem(16);
  height: rem(40);
  @include body-default-desktop;

  &::placeholder {
    color: var(--mantine-color-gray-7);
  }

  &:hover {
    outline: 1px solid var(--mantine-color-gray-6);
    outline-offset: -1px;
  }

  &:focus {
    background-color: var(--mantine-color-gray-0);
    outline: 2px solid var(--mantine-color-blue-0);
    outline-offset: -2px;

    & .section {
      outline-color: var(--mantine-color-blue-0);
    }
  }
}

.passwordInput {
  border: 0;

  &:focus,
  &:hover {
    outline: 0;
  }
}

.passwordInnerInput {
  &:hover {
    outline: 1px solid var(--mantine-color-gray-6);
    outline-offset: -1px;
  }

  &:focus {
    background-color: var(--mantine-color-gray-0);
    outline: 2px solid var(--mantine-color-blue-0);
    outline-offset: -2px;
  }
}

.checkboxInput {
  background-color: var(--mantine-color-gray-4);

  &:checked {
    background-color: var(--mantine-color-blue-0);
    outline-color: var(--mantine-color-blue-0);
  }
}

.section {
  color: var(--mantine-color-gray-7);
}

.selectDropdown {
  border: 1px solid var(--mantine-color-gray-6);
  @include elevation-1;
}

.selectOption {
  &:hover {
    background-color: var(--mantine-color-gray-4);
  }
}

.tabsList {
  flex-wrap: nowrap;
  overflow: scroll;
  scrollbar-width: none;
}
