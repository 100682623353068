// -----------------------------------
// Common
// -----------------------------------

@mixin h6 {
  font-family: var(--font-basically-a-mono);
  font-size: rem(14);
  line-height: rem(24);
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

@mixin body-small {
  font-size: rem(16);
  line-height: rem(28);
  color: var(--mantine-color-gray-7);
}

// -----------------------------------
// Desktop
// -----------------------------------

@mixin h1-desktop {
  font-size: rem(74);
  line-height: rem(80);
  font-weight: 500;
  margin: 0;
  padding-bottom: rem(4);
  letter-spacing: -0.03em;
}

@mixin h2-desktop {
  font-size: rem(41);
  line-height: rem(48);
  font-weight: 500;
  margin: 0;
  letter-spacing: -0.02em;
}

@mixin h3-desktop {
  font-size: rem(32);
  line-height: rem(40);
  font-weight: 500;
  margin: 0;
  letter-spacing: -0.02em;
}

@mixin h4-desktop {
  font-size: rem(26);
  line-height: rem(36);
  font-weight: 500;
  margin: 0;
}

@mixin h5-desktop {
  font-size: rem(20);
  line-height: rem(32);
  font-weight: 500;
  margin: 0;
}

@mixin h6-desktop {
  @include h6;
}

@mixin body-default-desktop {
  font-size: rem(18);
  line-height: rem(32);
}

@mixin body-small-desktop {
  @include body-small;
}

@mixin body-extra-small-desktop {
  font-size: rem(14);
  line-height: rem(24);
  color: var(--mantine-color-gray-7);
}

// -----------------------------------
// Mobile
// -----------------------------------

@mixin h1-mobile {
  font-size: rem(41);
  line-height: rem(48);
  font-weight: 500;
  margin: 0;
  letter-spacing: -0.04em;
}

@mixin h2-mobile {
  font-size: rem(32);
  line-height: rem(40);
  font-weight: 500;
  margin: 0;
  letter-spacing: -0.02em;
}

@mixin h3-mobile {
  font-size: rem(26);
  line-height: rem(36);
  font-weight: 500;
  margin: 0;
}

@mixin h4-mobile {
  font-size: rem(20);
  line-height: rem(32);
  font-weight: normal;
  margin: 0;
}

@mixin h5-mobile {
  font-size: rem(18);
  line-height: rem(28);
  font-weight: 500;
  margin: 0;
}

@mixin h6-mobile {
  @include h6;
}

@mixin body-default-mobile {
  font-size: rem(18);
  line-height: rem(28);
}

@mixin body-small-mobile {
  @include body-small;
}

@mixin body-extra-small-mobile {
  font-size: rem(14);
  line-height: rem(20);
  color: var(--mantine-color-gray-7);
}
